import { MEDIA_TYPE } from "./Constants";

import DiagonalImage from "../assets/product-imgs/diagonal.png";
import ProductWithCatImage from "../assets/product-imgs/product-with-cat.png";
import EasyCleaningImage from "../assets/product-imgs/easy-cleaning.png";
import StructureOverviewImage from "../assets/product-imgs/structure-overview.png";
import TrackHealthImage from "../assets/product-imgs/track-health.png";
import LitterBoxFeatureImg from "../assets/product-imgs/product-feature-illustration.png";
import AutoCleaningIcon from "../assets/icons/settings.svg";
import OdorManagementIcon from "../assets/icons/litter.svg";
import HealthTrackingIcon from "../assets/icons/smartphone.svg";
import MultiCatsIcon from "../assets/icons/cat.svg";
import LitterBoxOrderImg from "../assets/product-imgs/order-img.png";

import LitterSandFeatureImg from "../assets/product-imgs/litter-feature-img.png";
import KipenziLitter1 from "../assets/product-imgs/kipenzi-litter-1.jpg";
import KipenziLitter2 from "../assets/product-imgs/kipenzi-litter-2.jpg";
import KipenziLitter3 from "../assets/product-imgs/kipenzi-litter-3.jpg";
import KipenziLitter4 from "../assets/product-imgs/kipenzi-litter-4.jpg";
import KipenziLitter5 from "../assets/product-imgs/kipenzi-litter-5.jpg";
import LitterNatural from "../assets/icons/litter-natural.png";
import LitterAbsorption from "../assets/icons/litter-rapid-absoption.png";
import LitterNoDust from "../assets/icons/litter-no-dust.png";
import LitterOdorFree from "../assets/icons/litter-odorfree.png";
import LitterSandOrderImg from "../assets/product-imgs/litter-img-order.png";

export const PRODUCT_DATA = {
  litterBox: {
    id: "smart-cat-litter-pal",
    preNameText: "India's First",
    productName: "Smart Litter Pal",
    productDesc:
      "Say hello to SmartLitterPal! It's the next-gen, super-rated, WiFi-connected, self-cleaning litter box for your adorable feline friends. 🐾",
    productRating: 4.8,
    cardImageFromMediaList: 0,
    mediaList: [
      {
        id: 1,
        type: MEDIA_TYPE.IMG,
        src: DiagonalImage,
        thumbnail: DiagonalImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 2,
        src: ProductWithCatImage,
        thumbnail: ProductWithCatImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 3,
        src: EasyCleaningImage,
        thumbnail: EasyCleaningImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 4,
        src: StructureOverviewImage,
        thumbnail: StructureOverviewImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 5,
        src: TrackHealthImage,
        thumbnail: TrackHealthImage,
        alt: "Kipenzi Automated Cat Litter Box",
      },
    ],
    aboutProduct: [
      {
        title: "App remote intelligent control",
        description:
          "App intelligently controls the operations of the device and timely understands cat toilet information",
      },
      {
        title: "Quick disassembly and easy cleaning",
        description:
          "You can quickly remove the barrel for water washing and cleaning.",
      },
      {
        title: "Suitability",
        description:
          "Suitable for cats (1kgs - 8kgs) It is recommended to use smart litter pal on manual for kittens less than 3 months and lactating cats.",
      },
      {
        title: "Dimensions",
        description: "48cm (W) x 50cm (H) x 51cm (D)",
      },
    ],
    additionalStats: {
      oneDesktop: "20,000+ cats love it",
      oneMobile: "20k+ cats love it",
      twoDesktop: "100,000+ happy pet parents",
      twoMobile: "100k+ happy pet parents",
    },
    productFeatureImage: LitterBoxFeatureImg,
    productFeatures: [
      {
        icon: AutoCleaningIcon,
        heading: "Auto-Cleaning",
        description:
          "Automatically removes waste after every use. No need to scoop the litter yourself.",
      },
      {
        icon: OdorManagementIcon,
        heading: "Odor management",
        description:
          "Automatically moves the clumps into a sealed drawer which helps reduce bad odor.",
      },
      {
        icon: HealthTrackingIcon,
        heading: "Health Tracking",
        description:
          "Monitor cat weight, track bathroom habits, and receive waste alerts with Smart Life App.",
      },
      {
        icon: MultiCatsIcon,
        heading: "Multi-Cat Friendly",
        description:
          "Single smart Litter Pal serves up to 5 cats, removing the need for multiple boxes.",
      },
    ],
    testimonials: [
      {
        name: "Private Account",
        rating: 5,
        message:
          "The product is perfect and my cat already loved it after only one day! Definitely recommended.",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "My cat used it already twice. He is a bit nervous when it's in the cleaning phase, but I think he will get used to it. For now, I keep the old and new toilets next to each other. Overall - very satisfied. Hope my cat will be satisfied as well with new fresh and clean litter for a long time!",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "Very good quality product, my kittens got used to it immediately with curiosity and interest.Costumer service is on superb level. Highly recommend 👍",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "I love it so much even my cat. This product has made my life more easy👍",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "The box arrived in a very good condition to me. Very simple to use it & my Cats use it immediately.Highly recommended",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "As we foster cats, we have up to 13 cats at any given time so we got 2 boxes. The boxes stay clean, the cats like to use them, THE BOX DOES NOT SMELL!! With so many cats, these have held up PERFECTLY, they pause when a cat tries to enter while the box is cleaning, and make litter changes easy! These don't take up much room and look cool!",
      },
    ],
    productOrderSectionImg: LitterBoxOrderImg,
    seoMeta: {
      title: "",
      description: "",
    },
  },
  litterSand: {
    id: "bentonite-cat-litter",
    preNameText: "",
    productName: "Kipenzi Cat Litter",
    productDesc: "Cat-tested, cat-approved – because every meow matters.🐾",
    productRating: 4.8,
    cardImageFromMediaList: 0,
    mediaList: [
      {
        id: 1,
        type: MEDIA_TYPE.IMG,
        src: KipenziLitter1,
        thumbnail: KipenziLitter1,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 2,
        src: KipenziLitter2,
        thumbnail: KipenziLitter2,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 3,
        src: KipenziLitter3,
        thumbnail: KipenziLitter3,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 4,
        src: KipenziLitter4,
        thumbnail: KipenziLitter4,
        alt: "Kipenzi Automated Cat Litter Box",
      },
      {
        id: 5,
        src: KipenziLitter5,
        thumbnail: KipenziLitter5,
        alt: "Kipenzi Automated Cat Litter Box",
      },
    ],
    aboutProduct: [
      {
        title: "Fights odor on contact",
        description:
          "Smell stays trapped deep inside the litter. Achieve over 97% ammonia reduction",
      },
      {
        title: "Purely All-Natural",
        description:
          "We're dedicated to your cat's health, which is why our cat litter is crafted using only the finest all-natural ingredients. No harmful chemicals or additives are found here.",
      },
      {
        title: "Litter that actually clumps",
        description:
          "Clumps tight for easy scooping. Effortlessly scoop and manage clumps, simplifying the task of maintaining a clean litter box.",
      },
      {
        title: "Fragrance",
        description: "Available in unscented and lavender fragrance.",
      },
    ],
    additionalStats: {
      oneDesktop: "20,000+ cats love it",
      oneMobile: "20k+ cats love it",
      twoDesktop: "100,000+ happy pet parents",
      twoMobile: "100k+ happy pet parents",
    },
    productFeatureImage: LitterSandFeatureImg,
    productFeatures: [
      {
        icon: LitterNatural,
        heading: "100% Natural",
        description: "This product is made of 100% natural bentonite clay",
      },
      {
        icon: LitterAbsorption,
        heading: "Ultra-Rapid Clumping",
        description:
          "This product quickly absorbs moisture upon contact with cat urine",
      },
      {
        icon: LitterNoDust,
        heading: "99% Dust Free",
        description:
          "It has a dust free formutation making it safe for pets & pet owners",
      },
      {
        icon: LitterOdorFree,
        heading: "Excellent Odour Control",
        description:
          "This litter features optimum odur control, helping you keep your home odor free",
      },
    ],
    testimonials: [
      {
        name: "Private Account",
        rating: 5,
        message:
          "The product is perfect and my cat already loved it after only one day! Definitely recommended.",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "My cat used it already twice. He is a bit nervous when it's in the cleaning phase, but I think he will get used to it. For now, I keep the old and new toilets next to each other. Overall - very satisfied. Hope my cat will be satisfied as well with new fresh and clean litter for a long time!",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "Very good quality product, my kittens got used to it immediately with curiosity and interest.Costumer service is on superb level. Highly recommend 👍",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "I love it so much even my cat. This product has made my life more easy👍",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "The box arrived in a very good condition to me. Very simple to use it & my Cats use it immediately.Highly recommended",
      },
      {
        name: "Private Account",
        rating: 5,
        message:
          "As we foster cats, we have up to 13 cats at any given time so we got 2 boxes. The boxes stay clean, the cats like to use them, THE BOX DOES NOT SMELL!! With so many cats, these have held up PERFECTLY, they pause when a cat tries to enter while the box is cleaning, and make litter changes easy! These don't take up much room and look cool!",
      },
    ],
    productOrderSectionImg: LitterSandOrderImg,
  },
};
